import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import SiteHead from "../component/head/SiteHead";
import {
  isOAuthUrl,
  isTestAgent,
  showIntercomNewMessage,
} from "../pixelmixer-webapp/src/util/Utils";
import Modal from "react-bootstrap/Modal";
import {
  useDataDog,
  useSourceForgeReviews,
  useTestimonials,
} from "../component/hooks";
import Script from "next/script";
import Footer from "../component/footer/Footer";
import ServiceTabs from "../component/home/ServiceTabs";
import FeatureTabs from "../component/home/FeatureTabs";
// import { Pacifico } from "@next/font/google";

const Home = () => {
  const [shouldRenderIFrame, setShouldRenderIFrame] = useState(false);
  const [isJQueryLoaded, setIsJQueryLoaded] = useState(false);
  const [isGsapLoaded, setIsGsapLoaded] = useState(false);
  const [isScrollTriggerLoaded, setIsScrollTriggerLoaded] = useState(false);
  const [isWaypointsLoaded, setIsWaypointsLoaded] = useState(false);
  const [isCounterLoaded, setIsCounterLoaded] = useState(false);
  const [isSlickLoaded, setIsSlickLoaded] = useState(false);
  const [isLodashLoaded, setIsLodashLoaded] = useState(false);
  const [isCircleDotsLoaded, setIsCircleDotsLoaded] = useState(false);
  const [isAosLoaded, setIsAosLoaded] = useState(false);
  const [isMainLoaded, setIsMainLoaded] = useState(false);

  const sliderRef = useRef();
  const carouselRef = useRef();
  useTestimonials(
    isSlickLoaded && sliderRef.current,
    isSlickLoaded && carouselRef.current
  );

  useEffect(() => {
    setShouldRenderIFrame(!isTestAgent() && !isOAuthUrl());
  }, []);

  useEffect(() => {
    if (isCircleDotsLoaded) window.loadCircleDots("welcome-header-background");
  }, [isCircleDotsLoaded]);

  useEffect(() => {
    if (isCounterLoaded) window.loadCounter();
  }, [isCounterLoaded]);

  useEffect(() => {
    if (isAosLoaded) {
      window.AOS.init();
      // AOS.init({ disable: "mobile" });
    }
  }, [isAosLoaded]);

  useEffect(() => {
    if (isMainLoaded) window.loadMain();
  }, [isMainLoaded]);

  useSourceForgeReviews();
  useDataDog();

  const router = useRouter();
  const toolsTab = router.query.tool;
  // const [showProductsModal, setShowProductsModal] = useState(false);
  // useEffect(() => {
  //   setShowProductsModal(!!toolsTab);
  // }, [!toolsTab]);

  // const pacifico = Pacifico({ display: "swap" });

  return (
    <>
      <SiteHead
        showNavBar={true}
        enableFloatingNavBar={true}
        isJQueryLoadedCallback={() => setIsJQueryLoaded(true)}
      />
      {/* <!--===== WELCOME STARTS =======--> */}
      <div className="welcome1-section-area">
        <div className="background-gradient"></div>
        <div id="welcome-header-background" className="circle-dots-container">
          <div className="circle-dots">
            <p className="zoom">
              <span className="zoom zoomin">+</span>
              <span className="zoom zoomout">-</span>
            </p>
            <p className="zoomlevel">
              <span className="percent">100</span> % - (
              <span className="width"></span>px)(
              <span className="height"></span>px)
            </p>
            <p>
              Dead: <span className="dead">0</span>
            </p>
            <p>
              Alive: <span className="alive">0</span>
            </p>
            <p>
              Drawn: <span className="drawn">0</span>
            </p>
            <p>
              <span className="fps">0</span> FPS
            </p>
            <a className="save" href="" download="capture.png">
              Save
            </a>
          </div>
        </div>
        <div className="welcome-header-blade-background">
          <img
            src="/static/website-assets/images/custom/play-blade-only-white.svg"
            alt=""
          />
        </div>
        <div className="background-gradient-overlay"></div>

        <div className="container">
          <div className="row">
            <div className="col-lg-10 m-auto">
              <div className="welcome-content-header heading1">
                <h1 data-aos="fade-up" data-aos-duration="1000">
                  Connect with Knowledge
                </h1>
                <h2 data-aos="fade-up" data-aos-duration="1200">
                  Record, share, and discover technical information.
                </h2>
                <div data-aos="fade-up" data-aos-duration="1400">
                  <p>
                    A collaborative video hub specially crafted for techies.
                  </p>
                </div>
                <div
                  className="welcome-btnarea text-center"
                  data-aos="fade-up"
                  data-aos-duration="1600"
                >
                  <Link href="/createAccount" className="header-btn">
                    14 Day Free Trial<i className="fa-solid fa-power-off"></i>
                  </Link>
                  <Link
                    href="#"
                    onClick={() =>
                      showIntercomNewMessage("I am interested in a demo.")
                    }
                    className="header-btn1"
                  >
                    Contact Us<i className="fa-solid fa-headset"></i>
                  </Link>
                </div>
                <div data-aos="fade-up" data-aos-duration="1800">
                  {shouldRenderIFrame && (
                    <iframe
                      id="welcome-video"
                      className="img-fluid"
                      src="https://pixelmixer.pixelmixer.com/video/23889?shareCode=EFAAGEWMCWSK&chromeless=true&galleryMode=false&playerOnly=true&backgroundColor=transparent"
                      scrolling="no"
                      allowfullscreen="true"
                    ></iframe>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--===== WELCOME ENDS =======--> */}

      {/* <!--===== OTHERS STARTS =======--> */}
      <div className="brand2-section-area sp8">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 m-auto">
              <div className="brand2-header text-center">
                <h4 data-aos="fade-up" data-aos-duration="1000">
                  High-tech organizations love PixelMixer
                </h4>
              </div>
            </div>
          </div>
          <div className="space30"></div>
          <div className="row">
            <div
              className="col-lg-12"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="brand2-logos">
                <div className="brand2-logo">
                  <img
                    src="/static/website-assets/images/custom/logo/gap.svg"
                    alt=""
                  />
                </div>
                <div className="brand2-logo">
                  <img
                    src="/static/website-assets/images/custom/logo/macys.svg"
                    alt=""
                  />
                </div>
                <div className="brand2-logo">
                  <img
                    src="/static/website-assets/images/custom/logo/walmart.svg"
                    alt=""
                  />
                </div>
                <div className="brand2-logo">
                  <img
                    src="/static/website-assets/images/custom/logo/priceline.png"
                    alt=""
                  />
                </div>
                <div className="brand2-logo">
                  <img
                    src="/static/website-assets/images/custom/logo/united.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--===== OTHERS ENDS =======--> */}
      <div
        data-bs-spy="scroll"
        data-bs-target="#navbar-example2"
        data-bs-root-margin="0px 0px -40%"
        data-bs-smooth-scroll="true"
        className="scrollspy-example bg-body-tertiary rounded-2"
        tabindex="0"
      >
        {/* <!--===== SLIDER AREA STARTS =======--> */}
        <div
          className="slider1-section-area"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div className="marquee-wrap-background">
            <div className="marquee-wrap container">
              <div className="fade-left" />
              <div className="fade-right" />
              <div className="marquee-text">
                <div className="brand-single-box">
                  <h3>Product Engineering</h3>
                </div>
                <div className="brand-single-box">
                  <img
                    src="/static/website-assets/images/elements/marque-elements1.svg"
                    alt=""
                  />
                </div>

                <div className="brand-single-box">
                  <h3>Program Management</h3>
                </div>
                <div className="brand-single-box">
                  <img
                    src="/static/website-assets/images/elements/marque-elements1.svg"
                    alt=""
                  />
                </div>
                <div className="brand-single-box">
                  <h3>Software Development</h3>
                </div>
                <div className="brand-single-box">
                  <img
                    src="/static/website-assets/images/elements/marque-elements1.svg"
                    alt=""
                  />
                </div>
                <div className="brand-single-box">
                  <h3>Product Management</h3>
                </div>
                <div className="brand-single-box">
                  <img
                    src="/static/website-assets/images/elements/marque-elements1.svg"
                    alt=""
                  />
                </div>
                <div className="brand-single-box">
                  <h3>High-tech Manufacturing</h3>
                </div>
                <div className="brand-single-box">
                  <img
                    src="/static/website-assets/images/elements/marque-elements1.svg"
                    alt=""
                  />
                </div>
                <div className="brand-single-box">
                  <h3>Coaching</h3>
                </div>
                <div className="brand-single-box">
                  <img
                    src="/static/website-assets/images/elements/marque-elements1.svg"
                    alt=""
                  />
                </div>
                <div className="brand-single-box">
                  <h3>IT</h3>
                </div>
                <div className="brand-single-box">
                  <img
                    src="/static/website-assets/images/elements/marque-elements1.svg"
                    alt=""
                  />
                </div>
                <div className="brand-single-box">
                  <h3>UX</h3>
                </div>
                <div className="brand-single-box">
                  <img
                    src="/static/website-assets/images/elements/marque-elements1.svg"
                    alt=""
                  />
                </div>

                <div className="brand-single-box">
                  <h3>Creative Design</h3>
                </div>
                <div className="brand-single-box">
                  <img
                    src="/static/website-assets/images/elements/marque-elements1.svg"
                    alt=""
                  />
                </div>
                <div className="brand-single-box">
                  <h3>Engineering</h3>
                </div>
                <div className="brand-single-box">
                  <img
                    src="/static/website-assets/images/elements/marque-elements1.svg"
                    alt=""
                  />
                </div>
                <div className="brand-single-box">
                  <h3>Project Management</h3>
                </div>
                <div className="brand-single-box">
                  <img
                    src="/static/website-assets/images/elements/marque-elements1.svg"
                    alt=""
                  />
                </div>
                <div className="brand-single-box">
                  <h3>Startups</h3>
                </div>
                <div className="brand-single-box">
                  <img
                    src="/static/website-assets/images/elements/marque-elements1.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--===== SLIDER AREA ENDS =======--> */}

        {/* <!--===== SERVICE AREA STARTS =======--> */}
        <div className="service1-section-area sp3" id="resources">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 m-auto">
                <div className="service1-header heading2 text-center">
                  <span data-aos="fade-up" data-aos-duration="800">
                    Intelligent Tools
                  </span>
                  <h2 data-aos="fade-up" data-aos-duration="1000">
                    Access your organization's collective wisdom
                  </h2>
                </div>
              </div>
              <div className="space60"></div>
            </div>

            <ServiceTabs shouldRenderIFrame={shouldRenderIFrame} />
          </div>
        </div>
        {/* <!--===== SERVICE AREA ENDS =======--> */}

        {/* <!--===== PROCESS AREA STARTS =======--> */}
        <div className="process-section-area sp3">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 m-auto">
                <div className="process-header heading4 text-center">
                  <span data-aos="fade-up" data-aos-duration="800">
                    How it Works
                  </span>
                  <h2 data-aos="fade-up" data-aos-duration="1000">
                    Connect Information Silos
                  </h2>
                </div>
              </div>
            </div>
            <div className="space60"></div>
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="process-images reveal">
                  <img
                    src="/static/website-assets/images/custom/dimitri-smiling.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="process-images-content heading4">
                  <span data-aos="fade-left" data-aos-duration="800">
                    Record
                  </span>
                  <h2 data-aos="fade-left" data-aos-duration="1000">
                    Knowledge is captured as it is shared
                  </h2>
                  <p data-aos="fade-left" data-aos-duration="1200">
                    The PixelMixer Assistant automatically joins your meetings,
                    takes notes and detects the topics being discussed.
                  </p>
                </div>
              </div>
              <div className="process-section2">
                <img
                  src="/static/website-assets/images/elements/process-elements1.png"
                  alt=""
                  className="process-elements1 d-lg-block d-none"
                />
                <img
                  src="/static/website-assets/images/elements/process-elements2.png"
                  alt=""
                  className="process-elements2 d-lg-block d-none"
                />
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div className="process-images-content heading4">
                      <span data-aos="fade-right" data-aos-duration="800">
                        Recap
                      </span>
                      <h2 data-aos="fade-right" data-aos-duration="1000">
                        Keeps everyone on the same page
                      </h2>
                      <p data-aos="fade-right" data-aos-duration="1200">
                        PixelMixer sends a summary, action items and smart
                        reminders to those who attended the meeting.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="process-images reveal">
                      <img
                        src="/static/website-assets/images/custom/pixie-will-patrick.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="process-images reveal">
                    <img
                      src="/static/website-assets/images/custom/pixie-handbump.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="process-images-content heading4">
                    <span data-aos="fade-left" data-aos-duration="800">
                      Connect
                    </span>
                    <h2 data-aos="fade-left" data-aos-duration="1000">
                      No-one misses out
                    </h2>
                    <p data-aos="fade-left" data-aos-duration="1200">
                      PixelMixer connects others with information based on the
                      topics, teams, projects and people they are interested in.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--===== PROCESS AREA ENDS =======--> */}

        {/* <!--===== WORK AREA STARTS =======--> */}
        <div className="works1-section-area sp3" id="product">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 m-auto">
                <div className="works-bg-area">
                  <img
                    src="/static/website-assets/images/custom/play-blade-only-color.svg"
                    alt=""
                    className="elements-works"
                  />
                  <FeatureTabs shouldRenderIFrame={shouldRenderIFrame} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--===== WORK AREA ENDS =======--> */}

        {/* <!--===== FEATURES AREA STARTS =======--> */}
        <div
          className="features1-section-area sp4 background-gradient"
          id="features"
        >
          <div className="background-gradient-overlay" style={{ top: 0 }}></div>
          <img
            src="/static/website-assets/images/custom/play-blade-only-white.svg"
            alt=""
            className="features-elements1"
          />
          <div className="container">
            <div className="row">
              <div className="col-lg-8 m-auto">
                <div className="features1-header heading2 text-center">
                  <span data-aos="fade-up" data-aos-duration="800">
                    Designed to Scale
                  </span>
                  <h2 data-aos="fade-up" data-aos-duration="1000">
                    Built for the Enterprise
                  </h2>
                </div>
                <div className="space60"></div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="features-box-area">
                  <div className="row">
                    <div
                      className="col-lg-6 col-md-6"
                      data-aos="fade-right"
                      data-aos-duration="1000"
                    >
                      <div className="features-author-box">
                        <div className="box-title">Privacy</div>
                        <p>
                          Stay in control with our user-friendly permissions.
                          Keep track of who has access and confidently share
                          with others.
                        </p>
                        <div className="reveal">
                          <img
                            src="/static/website-assets/images/custom/privacy-settings.png"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg-6 col-md-6"
                      data-aos-duration="1000"
                      data-aos="fade-left"
                    >
                      <div className="features-author-box">
                        <div className="box-title">Security Policies</div>
                        <p>
                          Configure your account with the access policies to
                          meet your organizational requirements. These
                          gaurdrails empower delegated management of the
                          workspace.
                        </p>
                        <div className="reveal">
                          <img
                            src="/static/website-assets/images/custom/security-policies.png"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg-4 col-md-6"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    >
                      <div className="features-author-box features2">
                        <div className="box-title">
                          SOC2/Type 2 Certification
                        </div>
                        <p>
                          The gold-standard in software security (SOC2),
                          continuously monitored via the #1 compliance platform
                        </p>
                        <div className="reveal">
                          <img
                            src="/static/website-assets/images/custom/SOC2.png"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className="col-lg-4 col-md-6"
                      data-aos="fade-up"
                      data-aos-duration="1200"
                    >
                      <div className="features-author-box features2">
                        <div className="box-title">Single Sign-on</div>
                        <p>
                          Users seamlessly access PixelMixer using your existing
                          sign-in provider. No additional username, password or
                          login id necessary.
                        </p>
                        <div className="reveal">
                          <img
                            src="/static/website-assets/images/custom/cloud-lock.png"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className="col-lg-4 col-md-6"
                      data-aos="fade-up"
                      data-aos-duration="1400"
                    >
                      <div className="features-author-box features2">
                        <div className="box-title">
                          Delegated Administration
                        </div>
                        <p>
                          User auto-provisioning and simple security profiles
                          reduce the workload on IT. Truly a single solution for
                          your organizational video needs.
                        </p>
                        <div className="reveal">
                          <img
                            src="/static/website-assets/images/custom/account-users.png"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--===== FEATURES AREA ENDS =======--> */}

        {/* <!--===== OTHERS AREA STARTS =======--> */}
        <div className="brand-section-area sp6">
          <div className="container">
            <div className="row">
              <div className="col-gl-6 m-auto">
                <div className="brand-header heading2 text-center">
                  <span data-aos="fade-up" data-aos-duration="800">
                    Integrations
                  </span>
                  <h2 data-aos="fade-up" data-aos-duration="1000">
                    Works wherever you do
                  </h2>
                </div>
              </div>
            </div>
            <div className="space30"></div>
          </div>
          <div className="marquee-wrap-background">
            <div
              className="marquee-wrap container"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div className="brand-single-box">
                <img
                  src="/static/website-assets/images/custom/logo/zoom.svg"
                  alt=""
                />
              </div>
              <div className="brand-single-box">
                <img
                  src="/static/website-assets/images/custom/logo/okta.svg"
                  alt=""
                />
              </div>
              <div className="brand-single-box">
                <img
                  src="/static/website-assets/images/custom/logo/ringcentral.svg"
                  alt=""
                />
              </div>

              <div className="brand-single-box">
                <img
                  src="/static/website-assets/images/custom/logo/google-meet.svg"
                  alt=""
                />
              </div>

              <div className="brand-single-box">
                <img
                  src="/static/website-assets/images/custom/logo/confluence.svg"
                  alt=""
                />
              </div>

              <div className="brand-single-box">
                <img
                  src="/static/website-assets/images/custom/logo/webex.svg"
                  alt=""
                />
              </div>

              <div className="brand-single-box">
                <img
                  src="/static/website-assets/images/custom/logo/gotomeeting.svg"
                  alt=""
                />
              </div>
              <div className="brand-single-box">
                <img
                  src="/static/website-assets/images/custom/logo/slack.svg"
                  alt=""
                />
              </div>
              <div className="brand-single-box">
                <img
                  src="/static/website-assets/images/custom/logo/teams.svg"
                  alt=""
                />
              </div>
              <div className="brand-single-box">
                <img
                  src="/static/website-assets/images/custom/logo/okta.svg"
                  alt=""
                />
              </div>

              <div className="brand-single-box">
                <img
                  src="/static/website-assets/images/custom/logo/auth0.svg"
                  alt=""
                />
              </div>

              <div className="brand-single-box">
                <img
                  src="/static/website-assets/images/custom/logo/microsoft-signin.svg"
                  alt=""
                />
              </div>

              <div className="brand-single-box">
                <img
                  src="/static/website-assets/images/custom/logo/google-signin.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        {/* <!--===== COUNTER AREA STARTS =======--> */}
        <div className="counter1-section-area sp1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div
                  className="counter-auhtor-area"
                  data-aos="fade-up"
                  data-aos-duration="800"
                >
                  <div className="row">
                    <div className="col-lg-3 col-md-6">
                      <div className="counter-box1 text-center">
                        <h3>
                          <span className="counter">450</span>
                        </h3>
                        <p>High-tech Customers</p>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div
                        className="counter-box1 text-center"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                      >
                        <h3>
                          <span className="counter">96</span>%
                        </h3>
                        <p>Faster Information Access</p>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div
                        className="counter-box1 text-center"
                        data-aos="fade-up"
                        data-aos-duration="1200"
                      >
                        <h3>
                          <span className="counter">220</span>K
                        </h3>
                        <p>Topics Indexed</p>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div
                        className="counter-box1 text-center counter-after"
                        data-aos="fade-up"
                        data-aos-duration="1400"
                      >
                        <h3>
                          <span className="counter">75</span>K
                        </h3>
                        <p>Questions Answered</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--===== COUNTER AREA ENDS =======--> */}
        <div className="sf-reviews-section-area justify-content-md-center">
          <div className="container d-flex">
            <div className="col d-flex align-items-center justify-content-center">
              <div
                className="sf-root review-badges"
                data-id="3561893"
                data-badge="heart-badge-white"
                data-variant-id="sf"
              >
                <a
                  href="https://sourceforge.net/software/product/PixelMixer/"
                  target="_blank"
                >
                  PixelMixer Reviews
                </a>
              </div>
            </div>
            <div className="col d-flex align-items-center justify-content-center">
              <div
                className="sf-root review-badges"
                data-id="3561893"
                data-badge="light-default"
                data-variant-id="sf"
              >
                <a
                  href="https://sourceforge.net/software/product/PixelMixer/"
                  target="_blank"
                >
                  PixelMixer Reviews
                </a>
              </div>
            </div>
            <div className="col d-flex align-items-center justify-content-center">
              <div
                className="sf-root review-badges"
                data-id="3561893"
                data-badge="customers-love-us-white"
                data-variant-id="sf"
              >
                <a
                  href="https://sourceforge.net/software/product/PixelMixer/"
                  target="_blank"
                >
                  PixelMixer Reviews
                </a>
              </div>
            </div>

            <div className="col d-flex align-items-center justify-content-center">
              <div
                className="sf-root review-badges"
                data-id="3561893"
                data-badge="users-love-us-new-white"
                data-variant-id="sd"
              >
                <a
                  href="https://slashdot.org/software/p/PixelMixer/"
                  target="_blank"
                >
                  PixelMixer Reviews
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* <!--===== TESTIMONIAL AREA STARTS =======--> */}
        <div className="testimonial1-section-area sp3" id="testimonials">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 m-auto">
                <div className="testimonial1-header text-center heading2">
                  <span data-aos="fade-up" data-aos-duration="800">
                    Testimonials
                  </span>
                  <h2 data-aos="fade-up" data-aos-duration="1000">
                    Brilliant Success Stories
                  </h2>
                </div>
              </div>
            </div>
            <div className="space60"></div>
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="testimonial-sliders">
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <div
                        className="testimonial-content-slider slider-nav1"
                        ref={sliderRef}
                      >
                        <div className="testimonial-slider-boxarea">
                          <div className="testimonial-img">
                            <div className="mans-slider-info">
                              <img
                                src="/static/website-assets/images/custom/testimonials/olga-rolf.jpg"
                                alt=""
                              />
                              <div className="content">
                                <a href="team.html">Olga Rolf</a>
                                <p>
                                  Program Manager - Gap, Walmart Labs, Mozilla
                                </p>
                              </div>
                            </div>
                            <ul>
                              <li>
                                <a href="#">
                                  <i className="fa-solid fa-star"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fa-solid fa-star"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fa-solid fa-star"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fa-solid fa-star"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fa-solid fa-star"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="testimonial5-all-content">
                            <p>
                              “PixelMixer has enabled our teams to quickly
                              create videos for on-boarding, training, knowledge
                              transfers and showcases so engineers can focus on
                              developing products instead of constantly
                              repeating themselves. Using this product, my teams
                              have been able to access knowledge silos, work
                              across timezones asynchronously and improve morale
                              through better visibility.”
                            </p>
                          </div>
                        </div>

                        <div className="testimonial-slider-boxarea">
                          <div className="testimonial-img">
                            <div className="mans-slider-info">
                              <img
                                src="/static/website-assets/images/custom/testimonials/kevin-berrebi.jpg"
                                alt=""
                              />
                              <div className="content">
                                <a href="team.html">Kevin Berrebi</a>
                                <p>Product Manager - Meta</p>
                              </div>
                            </div>
                            <ul>
                              <li>
                                <a href="#">
                                  <i className="fa-solid fa-star"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fa-solid fa-star"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fa-solid fa-star"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fa-solid fa-star"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fa-solid fa-star"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="testimonial5-all-content">
                            <p>
                              “As a product manager at a fast moving
                              tech-company, I am always on the lookout for tools
                              that help me brainstorm and share ideas with
                              others. With PixelMixer, I can quickly capture my
                              thoughts and get direct feedback asynchronously
                              from anyone, anywhere in the world.”
                            </p>
                          </div>
                        </div>

                        <div className="testimonial-slider-boxarea">
                          <div className="testimonial-img">
                            <div className="mans-slider-info">
                              <img
                                src="/static/website-assets/images/custom/testimonials/olivia-hansen.jpg"
                                alt=""
                              />
                              <div className="content">
                                <a href="team.html">Olivia Hansen</a>
                                <p>President - Spiritual Life TV</p>
                              </div>
                            </div>
                            <ul>
                              <li>
                                <a href="#">
                                  <i className="fa-solid fa-star"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fa-solid fa-star"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fa-solid fa-star"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fa-solid fa-star"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fa-solid fa-star"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="testimonial5-all-content">
                            <p>
                              “We love PixelMixer for it&apos;s simplicity and
                              amazing capabilities. The comment and
                              collaboration tools are fun, effective and keep
                              everyone on our team in the loop - including
                              external content creators.”
                            </p>
                          </div>
                        </div>

                        <div className="testimonial-slider-boxarea">
                          <div className="testimonial-img">
                            <div className="mans-slider-info">
                              <img
                                src="/static/website-assets/images/custom/testimonials/asad-kemal.jpg"
                                alt=""
                              />
                              <div className="content">
                                <a href="team.html">Asad Kemal</a>
                                <p>Sr Director of UX - SS&amp;C</p>
                              </div>
                            </div>
                            <ul>
                              <li>
                                <a href="#">
                                  <i className="fa-solid fa-star"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fa-solid fa-star"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fa-solid fa-star"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fa-solid fa-star"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fa-solid fa-star"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="testimonial5-all-content">
                            <p>
                              “My passion is building teams that design amazing
                              customer experiences. PixelMixer provides critical
                              features that elevate communication and enhances
                              team performance through collaboration.”
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div
                        className="teimonial-slider-nav-area foter-carousel"
                        ref={carouselRef}
                      >
                        <div className="testimonial-slider-img reveal">
                          <img
                            src="/static/website-assets/images/custom/testimonials/olga.jpg"
                            alt=""
                          />
                        </div>
                        <div className="testimonial-slider-img reveal">
                          <img
                            src="/static/website-assets/images/custom/testimonials/kevin.jpg"
                            alt=""
                          />
                        </div>
                        <div className="testimonial-slider-img reveal">
                          <img
                            src="/static/website-assets/images/custom/testimonials/olivia-award.jpg"
                            alt=""
                          />
                        </div>
                        <div className="testimonial-slider-img reveal">
                          <img
                            src="/static/website-assets/images/custom/testimonials/asad.jpg"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--===== TESTIMONIAL AREA ENDS =======--> */}

        {/* <!--===== BLOG AREA STARTS =======--> */}
        <div className="blog1-section-area sp3" id="blog">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 m-auto">
                <div className="blog1-header text-center heading2">
                  <span data-aos="fade-up" data-aos-duration="800">
                    Blog
                  </span>
                  <h2 data-aos="fade-up" data-aos-duration="1000">
                    High-tech Teams at Work
                  </h2>
                </div>
              </div>
            </div>
            <div className="space60"></div>
            <div className="row">
              <div
                className="col-lg-4 col-md-6"
                data-aos="zoom-out"
                data-aos-duration="800"
              >
                <div className="blog1-boxarea">
                  <a href="https://blog.pixelmixer.com/how-pixelmixer-empowers-high-performance-software-development-teams/">
                    <div className="blog-img">
                      <img
                        src="/static/website-assets/images/custom/blog/high-performance-at-scale.jpg"
                        alt=""
                      />
                    </div>
                  </a>
                  <div className="blog-all-content-area">
                    <div className="blog1-content">
                      <img
                        src="/static/website-assets/images/icons/calender-img1.svg"
                        alt=""
                      />
                      <a href="#">November 21, 2023</a>
                    </div>
                    <div className="blog-main-content">
                      <a href="https://blog.pixelmixer.com/how-pixelmixer-empowers-high-performance-software-development-teams/">
                        High Performance Software Development at Scale
                      </a>
                      <p>
                        Balancing the pressures of time and quality are an
                        essential skill for software leaders...
                      </p>
                      <a
                        href="https://blog.pixelmixer.com/how-pixelmixer-empowers-high-performance-software-development-teams/"
                        className="learnmore"
                      >
                        Learn More <i className="fa-solid fa-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6"
                data-aos="zoom-out"
                data-aos-duration="1000"
              >
                <div className="blog1-boxarea">
                  <a href="https://blog.pixelmixer.com/why-do-teams-love-pixelmixer/">
                    <div className="blog-img">
                      <img
                        src="/static/website-assets/images/custom/blog/why-teams-love-pixelmixer.jpg"
                        alt=""
                      />
                    </div>
                  </a>
                  <div className="blog-all-content-area">
                    <div className="blog1-content">
                      <img
                        src="/static/website-assets/images/icons/calender-img1.svg"
                        alt=""
                      />
                      <a href="#">January 19, 2024</a>
                    </div>
                    <div className="blog-main-content">
                      <a href="https://blog.pixelmixer.com/why-do-teams-love-pixelmixer/">
                        Why do Teams Love PixelMixer?
                      </a>
                      <p>
                        Some backstory on PixelMixer and the value realized by
                        some noteworthy customers...
                      </p>
                      <a
                        href="https://blog.pixelmixer.com/why-do-teams-love-pixelmixer/"
                        className="learnmore"
                      >
                        Learn More <i className="fa-solid fa-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6"
                data-aos="zoom-out"
                data-aos-duration="1200"
              >
                <div className="blog1-boxarea">
                  <a href="https://blog.pixelmixer.com/remote-teamwork-productivity/">
                    <div className="blog-img">
                      <img
                        src="/static/website-assets/images/custom/blog/remote-teamwork.jpg"
                        alt=""
                      />
                    </div>
                  </a>

                  <div className="blog-all-content-area">
                    <div className="blog1-content">
                      <img
                        src="/static/website-assets/images/icons/calender-img1.svg"
                        alt=""
                      />
                      <a href="#">May 16, 2024</a>
                    </div>
                    <div className="blog-main-content">
                      <a href="https://blog.pixelmixer.com/remote-teamwork-productivity/">
                        Hybrid Work and Productivity
                      </a>
                      <p>
                        &quot;If your engineering organization is not as or more
                        productive while working remotely...&quot;
                      </p>
                      <a
                        href="https://blog.pixelmixer.com/remote-teamwork-productivity/"
                        className="learnmore"
                      >
                        Learn More <i className="fa-solid fa-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="space30"></div>
                <div
                  className="div text-center"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                  <a href="https://blog.pixelmixer.com/" className="header-btn">
                    More Thought Leadership<i className="fa-solid fa-lightbulb"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--===== BLOG AREA ENDS =======--> */}
      </div>
      <Footer includeSignup={true} />
      {isJQueryLoaded && (
        <>
          <Script
            src="https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.5/lodash.min.js"
            onReady={() => setIsLodashLoaded(true)}
          ></Script>
          <Script
            src="/static/website-assets/js/plugins/gsap.min.js"
            onReady={() => setIsGsapLoaded(true)}
          ></Script>
          <Script
            src="/static/website-assets/js/plugins/slick-slider.js"
            onReady={() => setIsSlickLoaded(true)}
          ></Script>
          <Script
            src="/static/website-assets/js/plugins/waypoints.js"
            onReady={() => setIsWaypointsLoaded(true)}
          ></Script>
          <Script
            src="/static/website-assets/js/plugins/aos.js"
            onReady={() => setIsAosLoaded(true)}
          ></Script>
        </>
      )}
      {isLodashLoaded && (
        <Script
          src="/static/website-assets/js/plugins/circle-dots.js"
          strategy="lazyOnload"
          onReady={() => setIsCircleDotsLoaded(true)}
        ></Script>
      )}
      {isWaypointsLoaded && (
        <>
          <Script
            src="/static/website-assets/js/plugins/counter.js"
            onReady={() => setIsCounterLoaded(true)}
          ></Script>
        </>
      )}
      {isGsapLoaded && (
        <>
          <Script
            src="/static/website-assets/js/plugins/ScrollTrigger.min.js"
            onReady={() => setIsScrollTriggerLoaded(true)}
          ></Script>
        </>
      )}
      {isScrollTriggerLoaded && isSlickLoaded && (
        <>
          <Script
            src="/static/website-assets/js/main.js"
            onReady={() => setIsMainLoaded(true)}
          ></Script>
        </>
      )}
    </>
  );
};

export default Home;

export const getServerSideProps = async ({ req, res }) => {

  return {
    props: {
      time: new Date().toISOString(),
    },
  };
};
