import { useState } from "react";

const FeatureTabs = ({ shouldRenderIFrame }) => {
  const [selectedFeature, setSelectedFeature] = useState("capture");
  const videoIFrameStyle = {
    border: 0,
    borderRadius: "10px",
    width: "100%",
    aspectRatio: "16 / 9",
    boxShadow: "0px 15px 20px 0px rgba(5, 5, 5, 0.5)",
    marginRop: "55px",
  };
  return (
    <div className="row">
      <div className="col-lg-5 m-auto">
        <div className="works-header heading2 text-center">
          <span data-aos="fade-up" data-aos-duration="800">
            All-in-One
          </span>
          <h2 data-aos="fade-up" data-aos-duration="1000">
            Knowledge Collaboration
          </h2>
        </div>
      </div>
      <div className="space60"></div>
      <div className="col-lg-10 m-auto">
        <div
          className="tabs-button text-center"
          data-aos="fade-right"
          data-aos-duration="1000"
        >
          <div className="nav-work">
            <a
              href="javascript:void(0)"
              onClick={() => setSelectedFeature("capture")}
              className={selectedFeature === "capture" ? "active" : ""}
            >
              Capture
            </a>
            <a
              href="javascript:void(0)"
              onClick={() => setSelectedFeature("share")}
              className={selectedFeature === "share" ? "active" : ""}
            >
              Share
            </a>
            <a
              href="javascript:void(0)"
              onClick={() => setSelectedFeature("collaborate")}
              className={selectedFeature === "collaborate" ? "active" : ""}
            >
              Collaborate
            </a>
            <a
              href="javascript:void(0)"
              onClick={() => setSelectedFeature("discover")}
              className={selectedFeature === "discover" ? "active" : ""}
            >
              Discover
            </a>
            <a
              href="javascript:void(0)"
              onClick={() => setSelectedFeature("connect")}
              className={selectedFeature === "connect" ? "active" : ""}
            >
              Connect
            </a>
            <a
              href="javascript:void(0)"
              onClick={() => setSelectedFeature("organize")}
              className={selectedFeature === "organize" ? "active" : ""}
            >
              Organize
            </a>
          </div>
        </div>

        <div className="tabs-auhtor-area">
          <div className="tabContainer">
            <div
              className={`tab-content ${
                selectedFeature === "capture" ? "active" : ""
              }`}
            >
              <div className="tabs-img-content-area">
                <div className="row align-items-center">
                  <div className="col-lg-7">
                    <div className="tabs-auhtor-img reveal">
                      {shouldRenderIFrame && selectedFeature === "capture" && (
                        <iframe
                          className="img-fluid"
                          src="https://pixelmixer.pixelmixer.com/video/24184?channelShareCode=DZEJINMBWLJK&chromeless=true&galleryMode=false&playerOnly=true&backgroundColor=transparent"
                          style={videoIFrameStyle}
                          scrolling="no"
                          allowfullscreen="true"
                        ></iframe>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="tabs-auhtor-content heading2">
                      <span>Capture</span>
                      <h2>Screen Recorder</h2>
                      <p>
                        Add a personal touch to communications with our easy to
                        use screen recorder, which includes engaging emotive
                        backgrounds and automatic transcription.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`tab-content ${
                selectedFeature === "share" ? "active" : ""
              }`}
            >
              <div className="tabs-img-content-area">
                <div className="row align-items-center">
                  <div className="col-lg-7">
                    <div className="tabs-auhtor-img reveal">
                      {shouldRenderIFrame && selectedFeature === "share" && (
                        <iframe
                          className="img-fluid"
                          src="https://pixelmixer.pixelmixer.com/video/24185?channelShareCode=DZEJINMBWLJK&chromeless=true&galleryMode=false&playerOnly=true&backgroundColor=transparent"
                          style={videoIFrameStyle}
                          scrolling="no"
                          allowfullscreen="true"
                        ></iframe>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="tabs-auhtor-content heading2">
                      <span>Share</span>
                      <h2>Send via email, SMS, or Whatsapp!</h2>
                      <p>
                        Sharing a video has never been easier, or more secure.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`tab-content ${
                selectedFeature === "collaborate" ? "active" : ""
              }`}
            >
              <div className="tabs-img-content-area">
                <div className="row align-items-center">
                  <div className="col-lg-7">
                    <div className="tabs-auhtor-img reveal">
                      {shouldRenderIFrame &&
                        selectedFeature === "collaborate" && (
                          <iframe
                            className="img-fluid"
                            src="https://pixelmixer.pixelmixer.com/video/23763?channelShareCode=DZEJINMBWLJK&chromeless=true&galleryMode=false&playerOnly=true&backgroundColor=transparent"
                            style={videoIFrameStyle}
                            scrolling="no"
                            allowfullscreen="true"
                          ></iframe>
                        )}
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="tabs-auhtor-content heading2">
                      <span>Collaborate</span>
                      <h2>Works with Slack and Teams</h2>
                      <p>
                        Stay informed within the flow of work. PixelMixer
                        integrates elegantly with your existing messaging tools.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`tab-content ${
                selectedFeature === "discover" ? "active" : ""
              }`}
            >
              <div className="tabs-img-content-area">
                <div className="row align-items-center">
                  <div className="col-lg-7">
                    <div className="tabs-auhtor-img reveal">
                      {shouldRenderIFrame && selectedFeature === "discover" && (
                        <iframe
                          className="img-fluid"
                          src="https://pixelmixer.pixelmixer.com/video/23826?channelShareCode=DZEJINMBWLJK&chromeless=true&galleryMode=false&playerOnly=true&backgroundColor=transparent"
                          style={videoIFrameStyle}
                          scrolling="no"
                          allowfullscreen="true"
                        ></iframe>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="tabs-auhtor-content heading2">
                      <span>Discover</span>
                      <h2>Ask the Chatbot Anything</h2>
                      <p>
                        Ever had a moment where you needed quick information
                        about a past meeting or task? Our AI chat bot is here to
                        help!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`tab-content ${
                selectedFeature === "connect" ? "active" : ""
              }`}
            >
              <div className="tabs-img-content-area">
                <div className="row align-items-center">
                  <div className="col-lg-7">
                    <div className="tabs-auhtor-img reveal">
                      {shouldRenderIFrame && selectedFeature === "connect" && (
                        <iframe
                          className="img-fluid"
                          src="https://pixelmixer.pixelmixer.com/video/23764?channelShareCode=DZEJINMBWLJK&chromeless=true&galleryMode=false&playerOnly=true&backgroundColor=transparent"
                          style={videoIFrameStyle}
                          scrolling="no"
                          allowfullscreen="true"
                        ></iframe>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="tabs-auhtor-content heading2">
                      <span>Connect</span>
                      <h2>Knowledge Finds You</h2>
                      <p>
                        Connect with relevant topics being discussed within the
                        organization, enhancing collaboration, knowledge
                        sharing, and informed decision-making.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`tab-content ${
                selectedFeature === "organize" ? "active" : ""
              }`}
            >
              <div className="tabs-img-content-area">
                <div className="row align-items-center">
                  <div className="col-lg-7">
                    <div className="tabs-auhtor-img reveal">
                      {shouldRenderIFrame && selectedFeature === "organize" && (
                        <iframe
                          className="img-fluid"
                          src="https://pixelmixer.pixelmixer.com/video/2915?shareCode=PRABIPULNXAE&chromeless=true&galleryMode=false&playerOnly=true&backgroundColor=transparent"
                          style={videoIFrameStyle}
                          scrolling="no"
                          allowfullscreen="true"
                        ></iframe>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="tabs-auhtor-content heading2">
                      <span>Organize</span>
                      <h2>Channels, Playlists and More!</h2>
                      <p>
                        A single, content rich landing page for your projects,
                        events, and teams. Integrates directly with Confluence,
                        Teams, and Slack.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureTabs;
