import { useState } from "react";

const ServiceTabs = ({}) => {
  const [selectedService, setSelectedService] = useState("assistant");

  return (
    <div className="service-vertical-tabs">
      <div className="grid">
        <div className="row">
          <div className="col-lg-5">
            <div className="service-tabs-grid">
              <div className="menu">
                <div
                  onClick={() => setSelectedService("assistant")}
                  className={selectedService === "assistant" ? "active" : ""}
                >
                  <span className="light"></span>
                  <div
                    className="tabs-content-area"
                    data-aos="fade-right"
                    data-aos-duration="800"
                  >
                    <div className="tabs-btn-icon">
                      <img
                        src="/static/website-assets/images/icons/notes-icon.svg"
                        alt=""
                      />
                    </div>
                    <div className="tabs-btn-content">
                      <h4>Meeting Assistant</h4>
                      <p>Summaries, action items and smart reminders.</p>
                    </div>
                  </div>
                </div>
                <div className="space16"></div>
                <div
                  onClick={() => setSelectedService("chatbot")}
                  className={selectedService === "chatbot" ? "active" : ""}
                >
                  <span className="light"></span>
                  <div
                    className="tabs-content-area"
                    data-aos="fade-right"
                    data-aos-duration="1000"
                  >
                    <div className="tabs-btn-icon">
                      <img
                        src="/static/website-assets/images/icons/chatbot-icon.svg"
                        alt=""
                      />
                    </div>
                    <div className="tabs-btn-content">
                      <h4>AI ChatBot</h4>
                      <p>Tap-in to organizational wisdom.</p>
                    </div>
                  </div>
                </div>
                <div className="space16"></div>
                <div
                  onClick={() => setSelectedService("recorder")}
                  className={selectedService === "recorder" ? "active" : ""}
                >
                  <span className="light"></span>
                  <div
                    className="tabs-content-area"
                    data-aos="fade-right"
                    data-aos-duration="1200"
                  >
                    <div className="tabs-btn-icon">
                      <img
                        src="/static/website-assets/images/icons/recorder-icon.svg"
                        alt=""
                      />
                    </div>
                    <div className="tabs-btn-content">
                      <h4>Screen Recorder</h4>
                      <p>Easy and creative, plus automatic transcription.</p>
                    </div>
                  </div>
                </div>
                <div className="space16"></div>
                <div
                  onClick={() => setSelectedService("network")}
                  className={selectedService === "network" ? "active" : ""}
                >
                  <span className="light"></span>
                  <div
                    className="tabs-content-area"
                    data-aos="fade-right"
                    data-aos-duration="1400"
                  >
                    <div className="tabs-btn-icon">
                      <img
                        src="/static/website-assets/images/icons/knowledge-icon.svg"
                        alt=""
                      />
                    </div>
                    <div className="tabs-btn-content">
                      <h4>Knowledge Network</h4>
                      <p>
                        Topic detection and notifications keep everyone in the
                        loop.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <div
              className="service-tabs-grid"
              data-aos="zoom-out"
              data-aos-duration="1200"
            >
              <ul className="service-tabs-content">
                <li className={selectedService === "assistant" ? "active" : ""}>
                  <div className="vertical-tabs-img reveal">
                    <img
                      src="/static/website-assets/images/custom/meeting-dimitri-pixie-closeup.jpg"
                      alt=""
                    />
                    <img
                      src="/static/website-assets/images/custom/autumn-notes.png"
                      alt=""
                      className="vertical-img2"
                    />
                  </div>
                </li>

                <li className={selectedService === "chatbot" ? "active" : ""}>
                  <div className="vertical-tabs-img reveal">
                    <img
                      src="/static/website-assets/images/custom/chatbot-message-tall.jpg"
                      alt=""
                    />
                    <img
                      src="/static/website-assets/images/custom/ai-assistant-small.jpg"
                      alt=""
                      className="vertical-img2"
                    />
                  </div>
                </li>

                <li className={selectedService === "recorder" ? "active" : ""}>
                  <div className="vertical-tabs-img reveal">
                    <img
                      src="/static/website-assets/images/custom/pixie-sharing.png"
                      alt=""
                    />
                    <img
                      src="/static/website-assets/images/custom/recorder-matte.png"
                      alt=""
                      className="vertical-img2"
                    />
                  </div>
                </li>

                <li className={selectedService === "network" ? "active" : ""}>
                  <div className="vertical-tabs-img reveal">
                    <img
                      src="/static/website-assets/images/custom/trending-topics.png"
                      alt=""
                    />
                    <img
                      src="/static/website-assets/images/custom/slack-updates.png"
                      alt=""
                      className="vertical-img2"
                    />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ServiceTabs;
